<ion-icon
  [name]="ionIcon"
  [color]="ionIconColor"
  class="confirmation-icon"
></ion-icon>
<h3 *ngIf="heading" innerHtml="{{ heading | translate }}"></h3>
<p *ngIf="message" innerHtml="{{ message | translate }}"></p>
<p>
  <ion-button
    (click)="onClick()"
    [fill]="buttonFill"
    *ngIf="showButton"
  >
    <ion-icon [name]="buttonIcon" slot="start" (click)="onClick()" *ngIf="buttonIcon"></ion-icon>
    {{ buttonLabel | translate }}
  </ion-button>
</p>
