import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'abbreviateEventClass'
})
export class AbbrevEventClassPipe implements PipeTransform {
  constructor(
) { }

  transform(value: string): any {
    return value ? value.substring(0, 3) : '';
  }

}
