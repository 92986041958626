<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ 'Select language' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="setLanguage()">{{ 'Select' | translate }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">{{ 'Select language' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <form [formGroup]="form1">
    <bh-input
      [formGroup]="form1"
      formControlName="language"
      type="radio-list"
      [selectOptions]="languages"
      selectValueProperty="code"
      selectLabelProperty="native"
    ></bh-input>
  </form>
</ion-content>
